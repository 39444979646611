* {
    margin: 0;
    padding: 0;
}

*, *:before, *:after {
    box-sizing: inherit;
}

html {
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    box-sizing: border-box;
}

body, div, span, object, p, q, blockquote,
sub, sup, a, abbr, acronym, img, small, h1, h2, h3, h4, h5, h6, b,
strong, big, ins, i, cite, em, var, dfn, address, del, dl, dt, dd,
ol, ul, li, pre, code, kbd, samp, tt, fieldset, legend, form, label, table, caption,
tbody, tfoot, thead, tr, th, td {

    border: 0;
    background: none;

}

table {
    border-spacing: 0;
    border-collapse: separate;
}

ul, ol {
    list-style: none;
}

a,
a:visited,
a:focus,
a:active {
    color: inherit;
    text-decoration: none;
}

.clear:before,
.clear:after {
    content: "";
    display: table;
}

.clear:after {
    clear: both;
}

.clear {
    zoom: 1; /* ie 6/7 */
}
