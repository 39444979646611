// Material UI Helpers
.bambourgh {
  .LB-MuiButtonBase-root {
    min-width: 0;
    outline: none !important;
  }

  .LB-MuiButton-root {
    line-height: initial;

    &:focus {
      outline: none;
    }
  }

  .LB-MuiPaper-root {
    color: $black;
  }
}


//.LB-MuiGrid-item,
//.LB-MuiPaper-root {
//  position: relative;
//}
@include media-breakpoint-down(lg) {
  .LB-MuiGrid-item {
    width: 100%;
  }
}

.LB-MuiCard-root {
  transition: $transition-base;
}

a.LB-MuiButton-outlined:hover {
  color: initial;
}

.min-w-auto {
  min-width: 5px !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-hidden {
  outline: none !important;
}

.LB-MuiButton-root.btn-inverse:hover {
  background-color: rgba(255,255,255,.07);
}

// Custom spacing

.mb-spacing-6 {
  margin-bottom: 24px;
}

.mb-spacing-6-x2 {
  margin-bottom: 48px;
}

// Forms

.bambourgh {

  .LB-MuiOutlinedInput-notchedOutline {
    transition: $transition-base;
  }

  .LB-MuiOutlinedInput-root:hover {
    .LB-MuiOutlinedInput-notchedOutline {
      border-color: $primary;
    }
  }
}
