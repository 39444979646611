@import "../../assets/scss/colors";

.tool-settings {
  display: none;
  position: absolute;
  left: 74px;
  top: -10px;
  border: 0.9px solid rgb(223, 227, 230);
  border-radius: 10px;
  background-color: $board-grey;
  padding: 15px;
  width: 330px;
  box-shadow: 0 4px 7px 0 rgba(0, 0, 0, 0.1);
  user-select: none;

  .tool-title, .slider-value {
    text-transform: uppercase;
    font-size: 12px;
    color: $board-text;
    font-weight: 900;
    margin-bottom: 10px;
    display: inline-block;
    font-family: "Source Sans Pro Regular", sans-serif;
  }

  .tool-size {
    display: flex;
    justify-content: space-between;

    .slider-value {
      text-transform: none;
    }
  }

  .available-tools {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    border: none;
    padding: 0;
    margin-bottom: 20px;

    > * {
      margin-bottom: 14px;
      margin-right: 12px;
    }

    &.disabled {
      cursor: not-allowed;
    }

    .icon-wrapper {
      &:not(:last-child) {
        margin-right: 10px;
      }
    }

    .active {
      > svg, > i {
        color: $board-primary !important;
      }
    }
  }

  .slider {
    margin-bottom: 20px;
  }

  .tools-horizontal-block {
    display: flex;

    > div {
      flex: 1;
    }
  }

  &.tool-settings-open {
    display: block;
  }

  &:after {
    content: '';
    width: 15px;
    height: 15px;
    z-index: 0;
    top: 25px;
    left: -7.5px;
    background: $board-grey;
    position: absolute;
    transform: rotate(45deg);
    border-left: 0.9px solid rgb(223, 227, 230);
    border-bottom: 0.9px solid rgb(223, 227, 230);
  }

  .background-type {
    display: flex;
    min-height: 100px;

    > label {
      position: relative;
      background-color: white;
      border: 3px solid white;
      margin-right: 25px;

      > input {
        display: none;
      }

      > span {
        text-transform: uppercase;
        margin-top: 4px;
        border-radius: 8px;
        background-color: white;
        color: $board-primary;
        box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12);
        padding: 0 4px;
        font-size: 13px;
        min-width: 50px;
        text-align: center;
        position: absolute;
        top: 40px;
      }

      &.active {

        border: 3px solid $board-primary;

        > span {
          background-color: $board-primary;
          color: white;
        }
      }
    }
  }
}
