@media (max-width: 767px) {
  .main {
    .section-title {
      font-size: 38px;
      line-height: 32px;
    }

    > .container {
      margin-top: 134px;
    }
  }
}

@media (max-width: 560px) {
  .main {
    .section-title {
      font-size: 32px;
      line-height: 40px;
    }

    > .container {
      margin-top: 0;
    }
  }
}
