.image {
  width: 490px;
  height: 480px;
}

.button {
  z-index: 1;
  color: black;
  background-color: white;
}

.button:hover {
  background-color: var(--lb-mui-palette-grey-a100);
}

.badge {
  font-size: 10px;
  width: 32px;
  height: 16px;
  left: 6px;
  top: 0;
  border-radius: 8px 8px 8px 0;
  text-transform: capitalize;
  background-color: #E71D32;
  color: #FFFFFF;
}
