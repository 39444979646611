@import "../../../../../assets/scss/colors";

.pages-sidebar__header {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  font-size: 15px;
  background-color: #dfe4e8;
  font-family: "Source Sans Pro Semi-Bold", sans-serif;
  user-select: none;

  &__close-button {
    margin-left: auto;
    border: 0;
    background-color: transparent;
    outline: none;
    color: $board-secondary;

    > i {
      transition: 400ms ease-in-out;
      vertical-align: middle;
    }

    &:hover {
      > i {
        transform: rotate(90deg)
      }
    }
  }
}
