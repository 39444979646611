.lb-grid {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;

  .lb-grid-cell {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    flex-basis: auto;
    flex-grow: 1;
  }

  &.lb-grid-center {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  &.lb-grid-full {
    > .lb-grid-cell {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 100%;
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
    }
  }

  &.lb-grid-gutters {
    margin: -1em 0 1em -1em;

    > .lb-grid-cell {
      padding: 12px 0 0 16px;
    ;
    }
  }
}
