//  Core

.bambourgh {
  .modal-content {
    box-shadow: $modal-content-box-shadow-xs;
  }
}

.LB-MuiDialog-paper {

  .LB-MuiDialogTitle-root {
    border-bottom: $gray-400 solid 1px;

    & > .LB-MuiTypography-root {
      font-weight: bold;
      font-size: $font-size-lg;
    }

  }

  .LB-MuiDialogActions-root {
    border-top: $gray-400 solid 1px;
  }

  &.modal-dark {
    color: $white;

    .LB-MuiDialogContentText-root {
      color: $white;
    }

    .LB-MuiDialogActions-root,
    .LB-MuiDialogTitle-root {
      border-color: rgba(255,255,255, .1);
    }
  }
}
