$color-primary: "0,150,136";
$color-primary-dark: "0,121,107";
$color-accent: "245,124,0";
$color-primary-contrast: "255,255,255";
$color-accent-contrast: "0, 0, 0";

$primary: #F57C00;
$secondary: #ff9800;

$primary-color-dark: #00796B;
$primary-color: #009688;
$primary-color-light: #B2DFDB;
$primary-color-text: #FFFFFF;
$accent-color: #FF9800;

$primary-text-color: #212121;
$secondary-text-color: #757575;
$divider-color: #BDBDBD;

$grey: #454f5b;
$grey-dark: #3d4651;
$grey-light: #8997a3;
$green: #1eb270;
$board-grey: #f4f6f8;
$board-primary: #32325d;
$board-secondary: #637381;
$board-text: #919eab;
$red: #d94b3f;
$red2: #e71d32;
$purple: #5c6ac4;
$blue: #0083ff;

$input-primary: #ebf0f7;
