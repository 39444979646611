.pages-sidebar__list-wrapper {
  overflow-y: scroll;
  overflow-x: hidden;
  height: calc(100% - 60px);
  background-color: #f9fafb;
  user-select: none;

  .pages-sidebar__list {
    margin-top: 32px;
    position: relative;
    flex-grow: 1;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
