.lb-btn {
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  border: 2px solid transparent;
  white-space: nowrap;
  font-size: 14px;
  line-height: 24px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;

  &.disabled {
    pointer-events: none;
    opacity: 0.6;
  }

  &.lb-btn-custom {
    padding: 6px 20px;
    color: #FFFFFF;
    transition: all 0.3s ease-in-out;
    border-radius: 30px;

    /**************
    /* White
    **************/
    &.lb-btn-custom-white {
      color: #FFF;
      border-color: #FFF;
      background-color: transparent;

      &:hover {
        color: #000000;
        background-color: #E1E1E1;
        border-color: #E1E1E1;
      }

      &:focus {
        color: #000000;
        background-color: #FFF;
      }

      &.lb-btn-custom-full {
        color: #000000;
        background-color: #FFF;

        &:hover, &:focus {
          color: #000000;
          border-color: #f5f5f5;
          background-color: #f5f5f5;
        }
      }
    }

    /**************
    /* Gray
    **************/
    &.lb-btn-custom-gray {
      color: #707A8B;
      border-color: #707A8B;
      background-color: transparent;

      &:hover {
        color: #FFFFFF;
        background-color: #707a8b;
      }

      &:focus {
        color: #000000;
        background-color: #5a626f;
      }

      &.lb-btn-custom-full {
        color: #FFF;
        background-color: #707A8B;

        &:hover, &:focus {
          color: #FFF;
          background-color: #5a626f;
        }
      }
    }

    /**************
    /* Orange
    **************/
    &.lb-btn-custom-orange {
      color: #F9BC3D;
      border-color: #F9BC3D;

      &:hover {
        color: #FFF;
        background-color: #E9B039;
      }

      &:focus {
        color: #FFF;
        background-color: #E9B039;
      }

      &.lb-btn-custom-full {
        color: #FFF;
        background-color: #F9BC3D;

        &:hover, &:focus {
          color: #FFF;
          background-color: #E9B039;
        }
      }
    }

    /****************
    /* Green
    ****************/
    &.lb-btn-custom-green {
      color: #1EB270;
      border-color: #1EB270;
      background-color: transparent;

      &:hover {
        color: #FFF;
        background-color: #1fcd84;
      }

      &:focus {
        color: #000000;
        background-color: #1fd28a;
      }

      &.lb-btn-custom-full {
        color: #FFF;
        background-color: #1EB270;

        &:hover, &:focus {
          color: #FFF;
          border-color: #1da565;
          background-color: #1da565;
        }
      }
    }

    /****************
    /* Blue
    ****************/
    &.lb-btn-custom-blue {
      color: #319AFF;
      border-color: #319AFF;
      background-color: transparent;

      &:hover {
        color: #FFF;
        background-color: #318ef1;
      }

      &:focus {
        color: #000000;
        background-color: #318ef1;
      }

      &.lb-btn-custom-full {
        color: #FFF;
        background-color: #319AFF;

        &:hover, &:focus {
          color: #FFF;
          background-color: #318ef1;
        }
      }
    }

    /****************
    /* Red
    ****************/
    &.lb-btn-custom-red {
      color: #D94B3F;
      border-color: #D94B3F;
      background-color: transparent;

      &:hover {
        color: #FFF;
        background-color: #ce4438;
      }

      &:focus {
        color: #000000;
        background-color: #ce4438;
      }

      &.lb-btn-custom-full {
        color: #FFF;
        background-color: #D94B3F;

        &:hover, &:focus {
          color: #FFF;
          background-color: #ce4438;
        }
      }
    }

    /****************
    /* Violet
    ****************/
    &.lb-btn-custom-violet {
      color: #32325D;
      border-color: #32325D;
      background-color: transparent;

      &:hover {
        color: #FFF;
        background-color: #32325D;
      }

      &:focus {
        color: #000000;
        background-color: #2c2c55;
      }

      &.lb-btn-custom-full {
        color: #FFF;
        background-color: #32325D;

        &:hover, &:focus {
          color: #FFF;
          background-color: #292951;
        }
      }
    }
  }
}
