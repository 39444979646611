$red: #FF3600;

.singular-page-layout {
  .logo {
    font-family: 'San Francisco', sans-serif;
    font-size: 34px;
    font-weight: 200;
    line-height: 40px;
    color: #000;

    > img {
      height: 40px;
    }
  }

  .green-background {
    background: linear-gradient(321.2deg, #171627 0%, #0DC78C 100%);
    box-shadow: 0 1px 0 0 #D9D9D9;
  }

  .left-side-holder {
    max-width: 500px;
  }

  .lb-holygrail-content-container {
    > .lb-grid {
      height: 100%;
    }
  }

  .right-side-container {
    height: 100%;

    .inner-container {
      height: 100%;

      > .lb-grid {
        height: 100%;
      }
    }
  }

  .left-side-container {
    position: relative;
    padding: 50px;
    height: 100%;

    .header-container {
      position: absolute;
    }

    .inner-container {
      height: 100%;
      padding-top: 60px;

      a {
        color: #319AFF;
        font-weight: 600;

        &:hover {
          color: #2b7dd7;
        }
      }

      > .lb-grid {
        height: 100%;
      }

      .title {
        font-size: 24px;
        line-height: 32px;
        font-weight: 400;
        color: #32325D;
        font-family: Roboto,Helvetica,Arial,sans-serif;
      }

      .text {
        font-size: 14px;
        line-height: 18px;
        font-weight: 600;
        color: #59626E;
        padding-top: 6px;
      }

      .form-container {
        padding: 20px 0 0;

        .server-error {
          text-align: left;
          color: $red;
        }

        .server-message{
          text-align: left;
          color: #1da565;
        }

        > .lb-btn:last-child {
          margin-top: calc(20px + 1em);
        }
      }

      .lb-btn {
        width: 100%;
        &.lb-btn-custom {
          display: block;
          font-size: 15px;
          line-height: 20px;
          font-weight: 600;
          text-transform: uppercase;
          padding: 16px 20px;
          border-radius: 3px;

          > svg {
            margin-right: 10px;
          }
        }
      }

      .separator {
        padding: 20px 0;
        position: relative;

        .separator-text {
          font-size: 15px;
          line-height: 20px;
          font-weight: 400;
          color: #878787;
          background-color: #FFF;
          display: table;
          z-index: 99;
          position: relative;
          margin: 0 auto;
          padding: 0 10px;
        }

        &:before {
          content: "";
          display: block;
          height: 1px;
          width: 100%;
          position: absolute;
          background-color: #D8D8D8;
          top: 50%;
          transform: perspective(1px) translateY(-50%);
          z-index: 98;
        }
      }
    }

    > .login-overlay{
      display: none;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.8);
      z-index: 10;

      &.show{
        display: flex;
        flex: 0 1 auto;
        flex-direction: column;
        flex-grow: 1;
        flex-shrink: 0;
        flex-basis: 100%;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
