//  Core

.alerts-alternate {
  &.LB-MuiAlert-root {
    border-width: 2px;
    border-style: solid;
    @include border-radius($border-radius-sm);
    border-left-width: 6px;
  }
}

.LB-MuiSnackbar-root {
  z-index: 2500;

  .LB-MuiSnackbarContent-root {
    color: $white;
  }
}

@each $color, $value in $theme-colors {
  .toastr-#{$color} {
    .LB-MuiSnackbarContent-root {
      background: $value;
      box-shadow: 0 0.313rem 0.8rem rgba($value, 0.5), 0 0.126rem 0.225rem rgba($value, 0.3) !important;
    }
  }
}

.LB-MuiAlert-standardWarning {
  border-color: $warning;
}

.LB-MuiAlert-standardError {
  border-color: $danger;
}

.LB-MuiAlert-standardSuccess {
  border-color: $success;
}

.LB-MuiAlert-standardInfo {
  border-color: $info;
}

.verification-notification {
  background-color: #EF7D54;
  color: white;
  min-height: 40px;
  font-size: $font-size-xxl;
  padding: 10px;
  text-align: center;

  i {
    margin-right: 5px;
  }

  a {
    text-decoration: underline;
    padding-left: 7px;
    white-space: nowrap
  }

  .resend-button {
    background-color: #EF7D54;
    outline: none;
    border: 2px solid white;
    padding: 4px 10px;
    border-radius: 20px;
    color: white;
    cursor: pointer;
    font-size: $font-size-lg;
    margin: 0 5px;

    &:hover {
      text-decoration: underline;
    }

    &.disabled {
      pointer-events: none;
      opacity: 0.6;
      text-decoration: none;
    }

    > svg {
      margin-left: 5px;
    }
  }
}

@media only screen and (max-width: 1200px) {
  .verification-notification {
    font-size: $font-size-xs;
  }
}
