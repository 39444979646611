.text-layer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .drop-target {
    position: relative;
    overflow: hidden;
  }

  .resizable-textarea__wrapper {
    position: absolute;
    padding: 20px;
    margin: 0;
    cursor: move;

    .resizable-textarea__border {
      position: relative;
      padding: 10px;
      margin: 0;

      .resizable-textarea__content {
        display: inline-block;
        position: relative;
        min-width: 20px;
        min-height: 20px;
        margin: 0;
        border: none;
        font-family: Roboto;
        box-sizing: border-box;
        white-space: pre;
        background-color: transparent;
        padding: 10px;
        line-height: 1;
        letter-spacing: normal;

        .resizable-textarea {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          overflow: hidden;
          resize: none;
          border: none;
          margin: 0;
          font-family: Roboto;
          box-sizing: border-box;
          white-space: pre;
          background-color: transparent;
          padding: 10px;
          line-height: 1;
        }
      }
    }
  }
}
