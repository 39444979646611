.chat-tab {
  $height: 600px;

  width: 382px;
  height: $height;
  position: fixed;
  bottom: 0;
  right: 0;
  flex: 1 0 auto;
  display: flex;
  z-index: 1202;
  overflow-y: auto;
  flex-direction: column;
  transition: -webkit-transform 225ms cubic-bezier(0.22, 0.61, 0.36, 1) 0ms;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  transform: translateY($height);
  font-family: "Source Sans Pro", sans-serif;

  .messages-container {
    padding: 10px;
    overflow: auto;
    height: calc(100% - 130px);

    &.without-input {
      height: 100%;
    }
  }

  &.chat-tab--opened {
    transform: translateY(0);
  }
}
