@import "../../../../../assets/scss/colors";

.chat-input {
  background-color: white;
  display: flex;
  align-items: center;
  border-top: 1px solid rgb(244, 246, 248);

  > input {
    height: 70px;
    border: none;
    padding: 0 10px;
    width: 100%;
    font-size: 14px;
  }

  > i {
    color: white;
    background-color: $green;
    padding: 10px;
    border-radius: 100%;
    margin: 0 10px;
  }
}
