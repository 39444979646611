
/*
Default font: Source Sans Pro
    Regular 400
    Semi Bold 600
    Bold 700
*/

/*
Extra font: San Francisco
    Ultra Light 100
    Thin 200
    Regular 400
    Medium 500
    Semi Bold 600
    Bold 700
*/
@import "../colors";

* {
  outline: none;
}

html {
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

#root{
  height: 100%;
  width: 100%;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  -webkit-text-fill-color: 'unset';
  -webkit-box-shadow: 'unset';
  transition: background-color 5000s ease-in-out 0s;
}

html, body {
  background-color: #FFFFFF;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  color: #677275;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

figure {
  margin: 0 !important;
  display: inline-block !important;
}

input {
  outline: 0;
  outline-offset: 0;
}

a {
  outline: 0;
  transition: color 0.1s ease-in;
}

a:focus {
  outline: 0;
  outline-offset: 0;
}

a:hover {
  text-decoration: none;
}

.lb-btn:focus,
.lb-btn:active:focus,
.lb-btn.active:focus,
.lb-btn.focus,
.lb-btn:active.focus,
.lb-btn.active.focus {
  outline: 0;
  outline-offset: 0;
}

.main {
  background-color: #FFFFFF;
  height: 100%;
}

.main h1,
.main h2,
.main h3,
.main p,
.main ol,
.main ul {
  margin: 0;
}

.main .wrapper {
  max-width: 1024px;
  margin: 0 auto;
}

.main .lb-container {
  width: 100%;
  margin-top: 80px;
}

.main .lb-container.no-margin {
  margin-top: 0;
}

.main .lb-container.lb-container-with-cover {
  padding: 0;
}

.main .section-title {
  font-size: 44px;
  line-height: 55px;
  color: #32325D;
  font-weight: 400;
}

.main .section-sub-title {
  font-size: 17px;
  line-height: 22px;
  color: #878787;
  padding-top: 10px;
}

.main .green-line {
  width: 32px;
  height: 2px;
  background-color: #3ECF8E;
  display: inline-block;
  vertical-align: super;
  margin-right: 20px;
}

@media (max-width: 1036px) {
  .main .wrapper > .lb-container {
    padding-left: 6px;
    padding-right: 6px;
  }
}

/* react-select styles v2 */
.react-select__control--isFocused {
  border-color: $green !important;
  box-shadow: 0 0 0 1px $green !important;
}

.react-select__option {
  &:hover {
    background-color: rgba(31, 177, 111, 0.3) !important;
  }
}

.react-select__option--isFocused {
  background-color: rgba(31, 177, 111, 0.3) !important;
}

.react-select__option--isSelected {
  background-color: $green !important;
}

.react-select__control {
  background-color: $input-primary !important;
  border-color: $input-primary !important;
  box-shadow: 0 0 1px $input-primary !important;
}


/* react-select styles v1 */

.Select {
  font-family: "Source Sans Pro Regular", sans-serif;

  &.is-open {
    > .Select-control {
      border-bottom-right-radius: 4px !important;
      border-bottom-left-radius: 4px !important;
      background: #fff;
      border-color: rgba(30, 178, 112, 0.5) !important;
    }
  }

  .Select-control {
    background-color: $input-primary !important;
    border: 1px solid $input-primary !important;

    &:hover {
      box-shadow: none !important;
    }
  }

  .Select-menu-outer {
    border: 1px solid rgba(30, 178, 112, 0.5) !important;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    top: 115% !important;

    .Select-option {

      &:first-child {
        border-top-right-radius: 4px;
        border-top-left-radius: 4px;
      }

      &:hover, &.is-focused {
        background-color: rgba(30, 178, 112, 0.3) !important;
      }
    }
  }
}

/* react tooltip */

.__react_component_tooltip {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid #dfe3e6;
  color: $board-primary;
  padding: 20px;
  opacity: 1 !important;
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}

.material-icons.md-18 { font-size: 18px; }
.material-icons.md-24 { font-size: 24px; }
.material-icons.md-36 { font-size: 36px; }
.material-icons.md-48 { font-size: 48px; }


main.canvas-wrapper {
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
  grid-area: board;
}

.canvas-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #F6F7F8;
  overflow: hidden;
}

.canvas-block {
  position: relative;

  > canvas {
    position: absolute;
    top: 0;
    left: 0;
    touch-action: none;
  }
}
