.pages-sidebar {
  width: 300px;
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1200;
  background-color: white;
  transition: -webkit-transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  box-shadow: 0px -10px 24px 0px rgba(175, 175, 175, 0.37);
  transform: translateX(300px);
  font-family: "Source Sans Pro", sans-serif;

  &--opened {
    transform: translate3d(0px, 0px, 0px);
  }
}
