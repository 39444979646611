.upgrade-dialog-carousel-dot {
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: 'none';
  text-align: center;
  height: 10px;
  line-height: 0;
}

.upgrade-dialog-carousel-dot li {
  position: relative;
  display: inline-block;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}
