@import "../../../../../assets/scss/colors";

@keyframes bounce {
  0% {
    transform: matrix3d(0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  4.7% {
    transform: matrix3d(0.45, 0, 0, 0, 0, 0.45, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  9.41% {
    transform: matrix3d(0.883, 0, 0, 0, 0, 0.883, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  14.11% {
    transform: matrix3d(1.141, 0, 0, 0, 0, 1.141, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  18.72% {
    transform: matrix3d(1.212, 0, 0, 0, 0, 1.212, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  24.32% {
    transform: matrix3d(1.151, 0, 0, 0, 0, 1.151, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  29.93% {
    transform: matrix3d(1.048, 0, 0, 0, 0, 1.048, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  35.54% {
    transform: matrix3d(0.979, 0, 0, 0, 0, 0.979, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  41.04% {
    transform: matrix3d(0.961, 0, 0, 0, 0, 0.961, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  52.15% {
    transform: matrix3d(0.991, 0, 0, 0, 0, 0.991, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  63.26% {
    transform: matrix3d(1.007, 0, 0, 0, 0, 1.007, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  85.49% {
    transform: matrix3d(0.999, 0, 0, 0, 0, 0.999, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  100% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
}

.message-content {
  margin: 3px 0;
  display: flex;
  align-items: flex-start;

  &:first-of-type {
    margin-top: 0;
  }

  > .sender-message {
    display: flex;
    flex-direction: column;
    align-items: baseline;

    .message-text {
      display: inline-block;
      background-color: $blue;
      color: white;
      padding: 7px 15px;
      border-radius: 20px;
      font-size: 15px;
      font-family: "Source Sans Pro Regular", sans-serif;
      word-break: break-word;
      max-width: 250px;
      animation: linear both bounce 500ms;
      white-space: break-spaces;
    }

    .sender-name {
      padding-bottom: 5px;
      margin-left: 3px;
      max-width: 170px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &.me {
    flex-direction: row-reverse;

    > .sender-message {
      align-items: flex-end;

      .message-text {
        background-color: #dfe4e8;
        color: $board-primary;
        margin-right: 5px;
        margin-left: 0;
        white-space: break-spaces;
      }

      .sender-name {

      }
    }
  }

  > .sender-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 50px;

    .message-created-time {
      font-size: 12px;
      padding: 2px;
    }
  }
}
