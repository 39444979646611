.container {
  padding-bottom: 10px;
}

.container:hover .drag-icon {
  opacity: 1;
}

.drag-icon {
    opacity: 0;
    transition-property: opacity, filter;
    transition-duration: 0.250s;
    transition-timing-function: linear;
}

.drag-icon-disabled {
    width: 24px;
    height: 24px;
}

.content {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    overflow: hidden;
}

.active {
    border: 1px solid var(--lb-palette-primary-main);
}
