@import "../../assets/scss/colors";

.icon-wrapper{
  width: 36px;
  height: 36px;
  padding: 6px;
  box-sizing: border-box;
  border-radius: 100%;
  background: white;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.17);
  display: flex;
  justify-content: center;
  align-items: center;

  &.big {
    width: 48px;
    height: 48px;
  }

  &:hover, &:focus{
    background-color: $board-primary;

    i, svg {
      color: white;
    }
  }

  &.disabled {
    opacity: 0.8;
    pointer-events: none;
    background: #d0d2d5;
  }

  .icon {
    display: block;
    width: 32px;
    height: 32px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    &.icon-color-picker {
        background-image: url("../images/ic_color_picker.png");
    }

    &.icon-pointer {
        background-image: url("../images/ic_pointer_chooser.png");
    }

    &.icon-clean {
        background-image: url("../images/ic_clean.png");
    }

    &.icon-robot {
        background-image: url("../images/ic_magic.svg");
    }
  }

  i {
    color: $board-primary;
    cursor: default;
    outline: none;
  }

  &.active {
    background-color: $board-primary;

    i {
      color: white;
    }
  }

  &.button-icon {
    i {
      cursor: pointer;
    }
  }

}
